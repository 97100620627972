import { useState, useEffect } from 'react';
import { useLocation, } from "react-router-dom"
import { BottomNavigationAction } from '@mui/material';
import { tabs } from './content';
import * as S from './styles';
import { hooks, mainOperations, mainSelectors, store, authSelectors } from 'state';
import { isDownloadAllowed } from '../../../utils';
import { appNames } from '../../../utils/constants';

const BottomNavigatorBar = () => {
  const [value, setValue] = useState('home')
  const { useAppSelector, useAppDispatch } = hooks
  const { selectmain } = mainSelectors
  const { selectAuth } = authSelectors

  const state = store.getState()
  const { isSidebarOpen, mainContent } = state.main
  const { setSidebarOpen, setMainContent, setModalOpen, setSelectedModalContent } = mainOperations
  const dispatch = useAppDispatch()
  const { selectedSuscriptionIndex, workbookFilters} = useAppSelector(selectmain)
  const { user } = useAppSelector(selectAuth)
  let userSettings = [
    {
        state: "California",
        categories: [
            "Flower",
            "Vape",
            "Concentrate",
            "Preroll",
            "Gummies And Chews",
            "Beverage"
        ],
        downloadAllowed: true
    }
  ]
  let canDownload: boolean | undefined = false

  userSettings = (((user as any)?.subscriptions[selectedSuscriptionIndex].settings))
  canDownload = true

  useEffect(()=>{
    if(mainContent !== undefined){
      setValue(mainContent)
    }
  },[mainContent])

  return (
    <S.Wrapper>
      <S.NavigationContainer
        showLabels
        value={value}
        onChange={(event, newValue) => {
          isSidebarOpen && dispatch(setSidebarOpen(!isSidebarOpen))
          event.preventDefault()
          dispatch(setMainContent(newValue))
          dispatch(setSelectedModalContent(newValue))
          dispatch(setModalOpen(true))
          setValue(newValue)
        }}
      >
        {tabs.map((tab) => {
          let isDisabled = false
          if(tab.label !== 'Filters'){
            isDisabled = !canDownload
          }
          return (
            <BottomNavigationAction
              key={tab.value}
              component={S.NavigationTabLink}
              to={tab.to}
              value={tab.value}
              label={tab.label}
              icon={tab.icon}
              disabled={isDisabled}
            />
          )
        })}
      </S.NavigationContainer>
    </S.Wrapper>
  );
};

export default BottomNavigatorBar;