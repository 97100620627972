import { Button, Container, SearchInput, Text } from 'components'
import { useEffect, useState } from 'react'
import { hooks, mainOperations, mainSelectors } from 'state'
import { MdEdit, MdStar } from 'react-icons/md'
import StyledWorkbookFilterList from './workbook-filter-list-styled'
import { ISavedFilter } from '../../../utils/constants/interfaces'


export const WorkbookFilterList = (props:any) => {
  const { useAppSelector, useAppDispatch } = hooks
  const { selectmain } = mainSelectors
  const { getWorkbookFilters, updateSelectedFilterSaved,getSigmaFilters } = mainOperations
  const { selectedWorkbookV2, savedWorkbookFilters} = useAppSelector(selectmain)
  const dispatch = useAppDispatch()
  const [searchValue, setSearchValue] = useState<any>()
  const { setIsModalOpen } = props

  const onSelectFilter = async (controlFilter: any) => {
    setIsModalOpen(false)
    await dispatch(updateSelectedFilterSaved(controlFilter))
    await dispatch(getSigmaFilters({workbookGuid: selectedWorkbookV2.workbookGuid, savedFilterGuid: controlFilter?.savedFilterGuid || null }))
  };

  const defaultFilterList = savedWorkbookFilters?.filter(({savedFilterName}:ISavedFilter)=> savedFilterName !== '') || []
  const [filterList, setfilterList] = useState([] as ISavedFilter[])

  useEffect(() => {
    if (!searchValue) {
      setfilterList(defaultFilterList)
    } else {
      const newFilterlist = defaultFilterList?.filter(({ savedFilterName }:any) => savedFilterName?.includes(searchValue)) || [] 
      setfilterList(newFilterlist)
    }
  }, [searchValue])
  
  useEffect(() => {
    setfilterList(defaultFilterList);
  }, [savedWorkbookFilters]);

  return (
    <StyledWorkbookFilterList className={'workbook-filter-list'}>
      <SearchInput onClear={() => setSearchValue('')} value={searchValue} placeholder='Search Filters' onChange={({ target: { value: newValue} }) => setSearchValue(newValue)}/>
      <Container className={'list'}>
        {
          filterList.length > 0
          && filterList.map((savedWorkboookFilter:ISavedFilter) => {
              const isDefault = savedWorkboookFilter.savedFilterGuid === selectedWorkbookV2?.defaultedTo?.savedFilterGuid
              return (
                savedWorkboookFilter.savedFilterName 
                  ? (
                    <Container className={'filter'} key={savedWorkboookFilter.savedFilterGuid}> 
                      <Container key={savedWorkboookFilter.savedFilterGuid} >
                        <Button onClick={() => onSelectFilter(savedWorkboookFilter)}>
                          <Text>{ savedWorkboookFilter.savedFilterName }</Text>
                        </Button>
                        {isDefault && <MdStar />}
                      </Container>
                      <Container>
                        <Button tooltip={{label: 'Edit Filter', position: 'left'}} onClick={() => {props.onEditFilter(savedWorkboookFilter);}}>
                          <MdEdit size={20}/>
                        </Button>
                      </Container>
                    </Container>
                  ) : ( <></> )
              )
            }
          )
        }
        {
          filterList.length === 0 && <Text>Empty</Text>
        }
      </Container>
    </StyledWorkbookFilterList>
  )
}

export default WorkbookFilterList