import { Container, DropdownMenu, Text } from "../../shared";
import { hooks, mainSelectors, mainOperations, dataTrackingOperations } from "../../../state";
import { useNavigate } from "react-router-dom"
import SidebarStyled from "./sidebar-styled";

const Sidebar = () => {
  const { selectHeaderTabs, selectmain, selectWorkbookTabs } = mainSelectors;
  const { selectWorkbookGroup, setSidebarOpen } = mainOperations;
  const { useAppSelector, useAppDispatch } = hooks
  const { selectedWorkbookGroup, appType} = useAppSelector(selectmain)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { trackEvent } = dataTrackingOperations
  const signOut = async () => {
    await dispatch(trackEvent({eventCategory: 'switch', values: JSON.stringify({ targetApp: 'PAM' }), event: 'switch_on_logout'}))
    window.location.assign(`${process.env.REACT_APP_LANDING_URL}/logout?originApp=${appType}`)
  }

  const headerTabs = useAppSelector(selectHeaderTabs)
  const workbookTabs = useAppSelector(selectWorkbookTabs)

  const workbookList = workbookTabs?.map((workbook: any) => ({ label: workbook.tabLabel, key: workbook.workbookGuid }))
  const selectNewWorkbook = (({ label: tabLabel, key }: any) => {
    dispatch(setSidebarOpen(false))
    navigate(`/${tabLabel.replaceAll(' ', '_').toLowerCase()}/${key}`)
  })

  const renderHeaderLink = (label: string) => {
    const isActive = label === selectedWorkbookGroup
    return (
      <Container key={label} className={`${isActive ? 'selected ' : ''}header-link`} onClick={() => dispatch(selectWorkbookGroup(label))}>
        <DropdownMenu
          onHover={() => dispatch(selectWorkbookGroup(label))}
          label={label}
          options={workbookList || []}
          onChange={(value: any) => { return value && selectNewWorkbook(value) }}
          arrowDirection="left-right"
        />
      </Container>
    )
  }
  return (
    <SidebarStyled>
      <Text textStyle="h4" color={'brandDark'}>Workbooks</Text>
      <Container className={'sidebar-workbooks-links'}>
        {headerTabs.map((link: any) => renderHeaderLink(link.groupString))}
      </Container>
      <div
        className="sidebar-external-links"
      >
        <div className="divider"></div>
        <Container className={'navigation-link'} onClick={() => {
          dispatch(trackEvent({ eventCategory: 'navigate', values: '', event: 'contact_us' }))
          // TODO: Move support@pistildata.com email to an env variable
          window.open('mailto:support@pistildata.com?subject=Subject&body=Body%20goes%20here')
        }}>
          <Text textStyle="h4" color="brandDark">Contact Us</Text>
        </Container>
        <div className="divider"></div>
        <Container className={'navigation-link'} onClick={() => {
              dispatch(trackEvent({eventCategory: 'navigate',values: '', event: 'help_center'}))
              window.open('https://helpcenter.pistildata.com/')
            }}>
          <Text textStyle="h4" color="brandDark">Help Center</Text>
        </Container>
        <div className="divider"></div>
        <Container className={'navigation-link'}
        onClick={() => signOut()}>
          <Text textStyle="h4" color="brandDark">Sign Out</Text>
        </Container>
      </div>
    </SidebarStyled>
  );
}

export default Sidebar