import { Container, WorkbookViewer, Text, WorkbookActions, Spinner } from 'components'
import { hooks, mainSelectors , authSelectors, mainOperations} from 'state'
import StyledDashboard from './dashboard-styled'
import { useEffect, useState, memo } from 'react'
import WorkbookPreview from 'components/layout/dashboard/WorkbookPreview/workbook-preview'
import { CrownIcon, LoadingSpinner } from 'assets/icons'
import { appNames } from 'utils/constants'
import { useParams } from 'react-router-dom'
import { getWorkbookTierIcon } from '../utils'
import Sidebar from '../components/layout/Sidebar'

export const Dashboard = memo(() => {
  const { useAppSelector } = hooks
  const { selectAuth } = authSelectors
  const { user } = useAppSelector(selectAuth)
  const { selectmain, selectWorkbookTier } = mainSelectors
  const { workbookPreview, selectedSuscriptionIndex, selectedWorkbookV2, isSidebarOpen} = useAppSelector(selectmain)
  let subscriptionTier = user.subscriptions[selectedSuscriptionIndex].subscriptionTier
  const workbookTier = useAppSelector(selectWorkbookTier)
  const params = useParams()
  const convertArrayToObject = (array:{imageUrl:string,screenSize: string}[]) => {
    return Object.fromEntries(array.map((a:{imageUrl:string, screenSize:string})=>Object.values(a).reverse()))
  }
  const [workbookImage, setWorkbookImage] = useState()
  const hasSubscription =  (subscriptionTier === workbookTier)
  useEffect(()=>{
    if(workbookPreview?.workbookPreviewImages?.length! > 0){
      setWorkbookImage(convertArrayToObject(workbookPreview?.workbookPreviewImages!))
    }
  },[workbookPreview])

  const renderWorkbookActions = () => {
    if(selectedWorkbookV2?.workbookGuid !== params.id){
    }else{
      return (<>
        <Container className={'title-container left-side'}>
          <Text textStyle='h3'>{selectedWorkbookV2?.workbookDisplayName}</Text>
        </Container>
        <WorkbookActions />
      </>)
    }
  }

  const icon =  getWorkbookTierIcon(workbookTier,undefined)
  return (
    <StyledDashboard expand className={'dashboard'}>
          <Container className={'workbooks-section'}>
            {      
              hasSubscription ? 
                <>
                  {renderWorkbookActions() }
                  <WorkbookViewer/>
                </>
                : workbookImage && <WorkbookPreview {...workbookPreview!} workbookImage={workbookImage} icon={icon} />
            }
          </Container>
          <Container className={`${'sidebar-section-container'} ${isSidebarOpen ? ' expanded' : ' colapsed'}`} >
              <Sidebar />
          </Container>
    </StyledDashboard>
  )
})

export default Dashboard