import { hooks, mainOperations, mainSelectors, dataTrackingOperations, store } from 'state';
import { getSelectedValues } from 'utils';
import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { IFiltersGroup, ISigmaControl, ISavedFilter } from 'utils/constants/interfaces';
import { eventsCategory, filterSelection } from '../../../utils/constants/events';
import StyledWorkbookViewer from './workbook-viewer-styled';
import { Spinner } from '../../shared';
import { LoadingSpinner } from '../../../assets';
import { useParams } from 'react-router-dom';

export const WorkbookViewer = () => {
  const [diff, setDiff] = useState<any>({});
  const { useAppSelector, useAppDispatch } = hooks;
  const { selectmain } = mainSelectors;
  const {
    saveWorkbookFilters,
    updateSelectedFilterSaved,
    updateDefaultFilterSaved,
    showWorkbookActions,
    getSigmaFilters,
    getSavedWorkbookFilters,
  } = mainOperations;
  const { selectedWorkbookV2, workbookFilters, isFetching, savedWorkbookFilters } = useAppSelector(selectmain);
  const { trackEvent } = dataTrackingOperations;
  const dispatch = useAppDispatch();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const autoFilterAppliedRef = useRef(false);
  const sigmaFilterAppliedRef = useRef(false);
  const lastAppliedFilterIdRef = useRef<string | null>(null);
  const memoizedSavedFilters = useMemo(() => savedWorkbookFilters, [savedWorkbookFilters]);

  

  const onSelectFilter = useCallback(async (controlFilter: ISavedFilter) => {
    try {
      await dispatch(updateSelectedFilterSaved(controlFilter));
      await dispatch(getSigmaFilters({
        workbookGuid: selectedWorkbookV2.workbookGuid,
        savedFilterGuid: controlFilter.savedFilterGuid || "",
      })).unwrap();
    } catch (error) {
    }
  }, [dispatch, selectedWorkbookV2?.workbookGuid]);
  
  

  const [resetState, setResetState] = useState(false);

  useEffect(() => {
    if (selectedWorkbookV2?.workbookGuid) {
      const storedGuid = sessionStorage.getItem("prevWorkbookGuid");
      if (!storedGuid) {
        sessionStorage.setItem("prevWorkbookGuid", selectedWorkbookV2.workbookGuid);
      } else if (storedGuid !== selectedWorkbookV2.workbookGuid) {
        sessionStorage.setItem("prevWorkbookGuid", selectedWorkbookV2.workbookGuid);
        setResetState(true); 
      }
    }
  }, [selectedWorkbookV2?.workbookGuid]);

  useEffect(() => {
    if (resetState) {
      dispatch(updateSelectedFilterSaved({} as ISavedFilter));
      dispatch(updateDefaultFilterSaved({} as ISavedFilter));
      dispatch(getSavedWorkbookFilters(selectedWorkbookV2.workbookGuid))
        .unwrap()
        .then((data) => {
          const savedWorkbookFilters = data?.savedWorkbookFilters || data;
          if (!Array.isArray(savedWorkbookFilters) || savedWorkbookFilters.length === 0) {
            return;
          }
            const defaultFilter = savedWorkbookFilters.find(f => f.isDefault);
            const autoSavingFilter = savedWorkbookFilters.find(f => f.isAutoSaving);
            const autoFilter = defaultFilter ?? autoSavingFilter;
          if (autoFilter) {
            dispatch(updateSelectedFilterSaved(autoFilter));
            dispatch(getSigmaFilters({
              workbookGuid: selectedWorkbookV2.workbookGuid,
              savedFilterGuid: autoFilter.savedFilterGuid || "",
            }))
              .unwrap()
              .then((sigmaData) => {
                if (!sigmaData || !sigmaData.sigmaControls) {
                  return;
                }
                const variables = sigmaData.sigmaControls.reduce((acc: Record<string, string>, control: ISigmaControl) => {
                  acc[control.sigmaControlId] = control.values;
                  return acc;
                }, {});
                const handleSigmaLoaded = (event: MessageEvent) => {
                  if (event.origin !== new URL(selectedWorkbookV2.embedUrl).origin) return;
                  if (event.data?.type === "workbook:loaded") {
                    iframeRef.current?.contentWindow?.postMessage(
                      { type: "workbook:variables:update", variables },
                      new URL(selectedWorkbookV2.embedUrl).origin
                    );
                    window.removeEventListener("message", handleSigmaLoaded);
                  }
                };
                window.addEventListener("message", handleSigmaLoaded);
                setTimeout(() => {
                  iframeRef.current?.contentWindow?.postMessage(
                    { type: "workbook:variables:update", variables },
                    new URL(selectedWorkbookV2.embedUrl).origin
                  );
                }, 500); 
              })
              .catch((error) => {
              });
          } else {
          }
        })
        .catch((error) => {
        });
      setResetState(false); 
    }
  }, [resetState]);
  
  




  useEffect(() => {
    if (autoFilterAppliedRef.current || selectedWorkbookV2?.selectedSavedFilter) return;
    if (!memoizedSavedFilters || memoizedSavedFilters.length === 0) return;
    if (!selectedWorkbookV2?.workbookGuid) return;
      const filters = memoizedSavedFilters as unknown as ISavedFilter[];
      const defaultFilter = filters.find(f => f.isDefault === true);
      const autoSavingFilter = filters.find(f => f.isAutoSaving === true);
      const autoFilter = defaultFilter ?? autoSavingFilter;
    if (autoFilter) {
      if (lastAppliedFilterIdRef.current === autoFilter.savedFilterGuid) return;
      autoFilterAppliedRef.current = true;
      lastAppliedFilterIdRef.current = autoFilter.savedFilterGuid;
      onSelectFilter(autoFilter);
    }
  }, [memoizedSavedFilters, selectedWorkbookV2?.workbookGuid, selectedWorkbookV2?.selectedSavedFilter]);

  const hookToEvent = useCallback(async (event: MessageEvent) => {
    const targetOrigin = 'https://app.sigmacomputing.com';
    if (event.origin === targetOrigin) {
      if (event.data.workbook) {
        const variables = event?.data?.workbook?.variables;
        if (variables) {
          dispatch({ type: "main/updateSigmaControls", payload: Object.keys(variables).map(key => ({
            sigmaControlId: key,
            values: variables[key]
          })) });
        }
  
        const variablesKeys = Object.keys(variables);
        const variableCount = variablesKeys.length;
        const { ctrl_DATE_RANGE: dateRangeValues } = variables;
        const includeTodayValue = 'prior-day-0';
        const hasIncludeToday = dateRangeValues && dateRangeValues.includes(includeTodayValue);
  
        if (variableCount === 1 && variablesKeys.includes('ctrl_DATE_RANGE') && hasIncludeToday) {
          let notIncludeTodayValue = 'prior-day-1';
          let [min] = dateRangeValues.split(',');
          let minValue = Number(min.split('-')[2]) + 1;
          let newValues = `min:prior-day-${minValue},max:${notIncludeTodayValue}`;
          setOutBoundEvent({
            variables: { ctrl_DATE_RANGE: newValues },
            eventType: event.data.type,
            hasChanges: event.data.type !== 'workbook:loaded',
          });
          setInBoundEvent({ type: 'workbook:variables:update', variables: { 'ctrl_DATE_RANGE': newValues } }, targetOrigin);
        } else {
          setOutBoundEvent({
            variables: variables,
            eventType: event.data.type,
            hasChanges: event.data.type !== 'workbook:loaded',
          });
        }
  
        await dispatch(updateDefaultFilterSaved({} as ISavedFilter));
      }
  
      if (event?.data?.type === 'workbook:fullscreen:onchange') {
        dispatch(showWorkbookActions(!event?.data?.fullScreen));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('message', hookToEvent);
    return () => {
      window.removeEventListener('message', hookToEvent);
    };
  }, [hookToEvent]);
  

  useEffect(() => {
    if (selectedWorkbookV2?.embedUrl && iframeRef.current?.contentWindow) {
      const targetOrigin = new URL(selectedWorkbookV2.embedUrl).origin;
      const controls = (selectedWorkbookV2?.appliedSigmaControls && selectedWorkbookV2.appliedSigmaControls.length > 0)
        ? selectedWorkbookV2.appliedSigmaControls
        : (selectedWorkbookV2?.sigmaControls || []);
      if (!controls || controls.length === 0) return;
      const variables = controls.reduce((acc: Record<string, string>, control: ISigmaControl) => {
        acc[control.sigmaControlId] = control.values;
        return acc;
      }, {} as Record<string, string>);
      iframeRef.current.contentWindow.postMessage(
        {
          type: "workbook:variables:update", 
          variables: variables,
        },
        targetOrigin
      );
    }
  }, [selectedWorkbookV2?.appliedSigmaControls, selectedWorkbookV2?.embedUrl]);

  useEffect(() => {
    const asyncUpdateControlFilters = async (controlFiltersParams: any | undefined, filtersGroup: IFiltersGroup | undefined) => {
      await dispatch(saveWorkbookFilters(filtersGroup!));
      await dispatch(updateSelectedFilterSaved({} as ISavedFilter));
    };
    if(diff?.variables){
      const vars = [] as ISigmaControl[]; 
      Object.values(diff?.variables).forEach((v, index) => {
        vars.push({ sigmaControlId: Object.keys(diff?.variables)[index], values: `${v}` });
      });
      let filteredVars = undefined;
      if (selectedWorkbookV2?.sigmaControls?.length > 0 && vars.length === 1) {
        filteredVars = selectedWorkbookV2?.sigmaControls.map(({ sigmaControlId }: ISigmaControl, index: number, array: ISigmaControl[]) => {
          if (sigmaControlId === vars[0].sigmaControlId) return vars[0];
          return array[index];
        });
      } else {
        filteredVars = vars;
      }
      if (vars.length > 0 && filteredVars?.length > 0) {
        const filtersGroup: IFiltersGroup = {
          workbookGuid: selectedWorkbookV2?.workbookGuid,
          filters: getSelectedValues(workbookFilters!),
          isDefault: false,
          isLastSelection: true,
          isAutoSaving: true,
          savedFilterName: null,
          sigmaControls: [...filteredVars],
        };
        asyncUpdateControlFilters(undefined, filtersGroup);
      }
    }
  }, [diff]);

  const setOutBoundEvent = (message: { eventType: string, variables: Record<string, any>, hasChanges: boolean }) => {
    const { variables, eventType, hasChanges } = message;
    setDiff({
      variables,
      eventType,
      hasChanges,
    });
  };

  const setInBoundEvent = (message: { type: string, variables: Record<string, any> }, url: string) => {
    const { type, variables } = message;
    iframeRef?.current?.contentWindow?.postMessage(
      { type, variables },
      url,
    );
  };

  const handleSigmaEvents = useCallback((event: MessageEvent) => {
    if (!selectedWorkbookV2?.embedUrl) return;
    const sigmaOrigin = new URL(selectedWorkbookV2.embedUrl).origin;
    if (event.origin !== sigmaOrigin) return;
    if (event.data?.type === "workbook:loaded") {
      if (sigmaFilterAppliedRef.current) return;
      sigmaFilterAppliedRef.current = true;
      dispatch(getSigmaFilters({
        workbookGuid: selectedWorkbookV2?.workbookGuid,
        savedFilterGuid: selectedWorkbookV2.selectedSavedFilter?.savedFilterGuid || ''
      }));
    }
  }, [selectedWorkbookV2?.embedUrl, selectedWorkbookV2?.selectedSavedFilter, dispatch]);
  

  useEffect(() => {
    window.addEventListener("message", handleSigmaEvents);
    return () => window.removeEventListener("message", handleSigmaEvents);
  }, [handleSigmaEvents]);
  

  return (
    <StyledWorkbookViewer className="workbook-viewer">
      <iframe
        title={selectedWorkbookV2?.workbookDisplayName}
        ref={iframeRef}
        key={selectedWorkbookV2?.workbookGuid}
        src={selectedWorkbookV2?.embedUrl}
      />
    </StyledWorkbookViewer>
  );
}

export default WorkbookViewer;
