import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiUtils, store } from 'state';
import { eventsCategory, filterActions, exportActions, scheduledExportActions } from 'utils/constants/events';
import { downloadErrorMessage } from 'utils/constants/labels';
import { authOperations, dataTrackingOperations } from '..';
import { getSelectedValues } from '../../../utils';
import { IDownloadPremiumExport, IFiltersGroup, ISavedFilter, IScheduledReport, IScheduledReportParams, ISaveWorkbookFiltersPayload } from '../../../utils/constants/interfaces';
import dataTrackingActions from '../data-tracking/actions';


const setMainContent = createAction(
  'main/setMainContent',
  (mainContent: string) => ({ payload: { mainContent } })
)
const setSelectedWorkbookGuid = createAction('main/setSelectedWorkbookGuid',(workbookGuid)=>({payload: {workbookGuid}}))
const setAppType = createAction(
  'main/setAppType',
  (appType: string) => ({ payload: { appType } })
)
const setBottomTabNavigation = createAction(
  'main/setBottomTabNavigation',
  (bottomTabSelected: string) => ({ payload: { bottomTabSelected } })
)

const setMenuOpen = createAction(
  'main/setMenuOpen',
  (isMenuOpen: boolean) => ({ payload: { isMenuOpen } })
)

const setSidebarOpen = createAction(
  'main/setSidebarOpen',
  (isSidebarOpen: boolean) => ({ payload: { isSidebarOpen } })
)

const setIsModalOpen = createAction('main/setIsModalOpen', (isModalOpen: boolean)=>({payload: { isModalOpen }}))

const setSelectedModalContent = createAction('main/setSelectedModalContent', (selectedModalContent: string) => ({ payload: { selectedModalContent }}))

const setModalOpen = createAction(
  'main/setModalOpen',
  (isModalOpen: boolean) => ({ payload: { isModalOpen } })
)
const setSelectedSuscriptionIndex = createAction(
  'main/setSelectedSuscriptionIndex',
  (selectedSuscriptionIndex: number) => ({ payload: { selectedSuscriptionIndex } })
)

const selectState = createAction(
  'main/selectState',
  (selectedState: string) => ({ payload: { selectedState } })
)

const getWorkbookPreview = createAsyncThunk(
  'main/getWorkbookPreview',
  async (workbookGuid: string) => {
    const response = await apiUtils.apiV2.get(`/WorkbookEmbed/WorkbookPreview/${workbookGuid}`)
    const { data } = response
    return ({...data})
  }
)

const selectWorkbook = createAction(
  'main/selectWorkbook',
  (selectedWorkbook: any) => {
    const { user } = store.getState().auth
    const { selectedSuscriptionIndex } = store.getState().main
    const workbook = {
      ...selectedWorkbook,
      ...user.subscriptions[selectedSuscriptionIndex].workbooks.find(({ workbookGuid }: any) => workbookGuid === (selectWorkbook as any).workbookGuid)
    }
    return ({ payload: { selectedWorkbook: workbook } })
  }
)

const selectCategories = createAction(
  'main/selectCategories',
  (selectedCategories: string) => ({ payload: { selectedCategories } })
)

const selectWorkbookGroup = createAction(
  'main/selectWorkbookGroup',
  (selectedWorkbookGroup: string) => ({ payload: { selectedWorkbookGroup } })
)

const showWorkbookActions = createAction(
  'main/hiddeWorkbookActions',
  (isOpen: boolean) => {
    return ({ payload: { isOpen }})
  }
)


const updateControlFilters = createAsyncThunk(
  'main/updateControlFilters',
  async (data: any, { rejectWithValue, dispatch }) => {
    await apiUtils.checkToken()
    const { updateUserWorkbookFilterList } = authOperations
    const { token, user } = store.getState().auth
    const { selectedWorkbook, selectedSuscriptionIndex } = store.getState().main
    const { trackEvent } = dataTrackingOperations
    const values: any = { ...data }
    const silentUpdate = data.silentUpdate
    delete values.preventWorbookUrlGeneration
    delete values.isLastSelection
    delete values.presetName
    delete values.callback
    delete values.categories
    delete values.isDefault
    delete values.isSelected
    delete values.hasChanges
    delete values.controlFilter
    const controlValues = Object.keys(values).map((k: any) => ({ controlId: k, values: data[k] }))
    if (!silentUpdate) {
      dispatch(resetActivityTimeout())
    }
    if (!values.state) return
    const queryParams: any = {
      controlFilterGuid: data.controlFilter?.controlFilterGuid,
      workbookGuid: selectedWorkbook?.workbookGuid,
      isDefault: data.isDefault,
      isLastSelection: data.isLastSelection,
      controlValues,
      state: data.state,
      categories: data.categories,
      name: data.presetName || ''
    }
    try {
      if (!data.preventWorbookUrlGeneration) {
        let content: any
        if (data.controlFilter) {
          const { data } = await axios.put(`${process.env.REACT_APP_API_URL}/v2/bitool/controlFilters`,
            queryParams,
            {
              headers: {
                ...apiUtils.getHeaders(),
                "Content-Type": "application/json-patch+json",
                Authorization: `${token?.token_type} ${token?.access_token}`
              },
            }
          );
          content = data.content
        } else {
          const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/v2/bitool/controlFilters`,
            queryParams,
            {
              headers: {
                ...apiUtils.getHeaders(),
                "Content-Type": "application/json-patch+json",
                Authorization: `${token?.token_type} ${token?.access_token}`
              },
            }
          );
          content = data.content
        }

        if (data.callback) {
          data.callback(data.state)
        }
        if (data.presetName) {
          const updatedWorkbook: any = user.subscriptions[selectedSuscriptionIndex].workbooks.map((wb: any) => {
            if (wb.workbookGuid === selectedWorkbook?.workbookGuid) {
              if (data.controlFilter) {
                return ({
                  ...wb,
                  controlFilters: [...wb.controlFilters.filter(({ controlFilterGuid }: any) => controlFilterGuid !== data.controlFilter.controlFilterGuid), { ...content }]
                })
              }
              return ({
                ...wb,
                controlFilters: [...wb.controlFilters, { ...content }]
              })
            }
            return (wb)
          })
          const updatedsubscription = { ...user.subscriptions[selectedSuscriptionIndex], workbooks: updatedWorkbook }
          const updatedsubscriptions = [...user.subscriptions]
          updatedsubscriptions.splice(selectedSuscriptionIndex, 1, updatedsubscription)

          const updatedUser = { ...user, subscriptions: updatedsubscriptions }
          const isNew = !data.controlFilter?.controlFilterGuid

          dispatch(trackEvent({ eventCategory: 'filter_action', values: `${selectedWorkbook.workbookTitle}: ${data.presetName}`, event: `filter_${isNew ? 'save' : 'update'}` }))
          dispatch(updateUserWorkbookFilterList(updatedUser))
          return ({
            hasChanges: data.hasChanges,
            controlFilter: content,
            isSelected: data.isSelected, variables: values,
            message: { message: `Filter ${data.presetName} succesfully ${isNew ? 'updated' : 'added'}.`, type: 'message' },
            selectedCategories: data.categories,
            selectedState: data.state,
            isDefault: data.isDefault
          })
        }
        return ({ isDefault: data.isDefault, isSelected: data.isSelected, hasChanges: data.hasChanges, controlFilter: content, variables: values, selectedCategories: data.categories, selectedState: data.state })
      }
      return ({ isDefault: data.isDefault, isSelected: data.isSelected, variables: values, selectedCategories: data.categories, selectedState: data.state })
    } catch (err) {
      dispatch(trackEvent({
        eventCategory: 'error',
        values: JSON.stringify({ err }),
        event: `updateControlFilters_error`
      }))

      if (!(err as Record<string, string>).response) {
        throw err
      }
      return rejectWithValue(err)
    }
  }
)

const deleteControlFilterV2 = createAsyncThunk(
  'main/deleteControlFilterV2',
  async ({ savedFilterGuid, isSelected, savedFilterName }: any, { rejectWithValue, dispatch }) => {
    await apiUtils.checkToken();
    const { trackEvent } = dataTrackingOperations;
    
    try {
      const { savedWorkbookFilters, selectedWorkbookV2 } = store.getState().main;
      const { workbookGuid } = selectedWorkbookV2; 
      await apiUtils.apiV2.delete(`/v2/Controls/workbook/${workbookGuid}/saved/${savedFilterGuid}`);

      let savedFilterTitle = '';
      const filteredWFs = savedWorkbookFilters?.filter((swf: ISavedFilter) => {
        if (swf.savedFilterGuid !== savedFilterGuid) {
          return true;
        } else {
          savedFilterTitle = swf.savedFilterName;
          return false;
        }
      });

      dispatch(updateSavedWorkbookFiltersList(filteredWFs!));

      let deletedFilter = {
        workbookGuid,
        filterName: savedFilterName
      };

      dispatch(trackEvent({
        eventCategory: eventsCategory.filter_action,
        values: deletedFilter,
        event: filterActions.filter_delete
      }));

      return ({
        isSelected,
        message: { message: `Filter ${savedFilterTitle} successfully deleted.`, type: 'message' }
      });
      
    } catch (err) {
      dispatch(trackEvent({
        eventCategory: eventsCategory.filter_action,
        values: JSON.stringify({ err }),
        event: `${filterActions.filter_delete}_error`
      }));

      dispatch(trackEvent({
        eventCategory: 'error',
        values: JSON.stringify({ err }),
        event: `deleteControlFilterV2_error`
      }));

      if (!(err as Record<string, string>).response) {
        throw err;
      }

      return rejectWithValue(err);
    }
  }
);


const deleteControlFilter = createAsyncThunk(
  'main/deleteControlFilter',
  async ({ controlFilterGuid, workbookGuid, isSelected }: any, { rejectWithValue, dispatch }) => {
    await apiUtils.checkToken()
    const { selectedWorkbook, selectedSuscriptionIndex } = store.getState().main
    const { trackEvent } = dataTrackingOperations
    const { token, user } = store.getState().auth
    const { updateUserWorkbookFilterList } = authOperations

    try {

      await axios.delete(
        `${process.env.REACT_APP_API_URL}/v2/bitool/controlFilters/${controlFilterGuid}`,
        {
          headers: {
            ...apiUtils.getHeaders(),
            "Content-Type": "application/json-patch+json",
            Authorization: `${token?.token_type} ${token?.access_token}`
          },
        }
      );
      let workbookTitle: any = ''
      const updatedWorkbooks = user.subscriptions[selectedSuscriptionIndex]?.workbooks.map((wb: any) => {
        if (wb.workbookGuid === workbookGuid) {
          workbookTitle = [...wb.controlFilters].filter((cf: any) => {
            return cf.controlFilterGuid === controlFilterGuid
          })[0].name
          const updatedValues = [...wb.controlFilters].filter((cf: any) => {
            return cf.controlFilterGuid !== controlFilterGuid
          })
          return { ...wb, controlFilters: updatedValues }
        }
        return wb
      })


      const newSubscriptions = [...user.subscriptions]
      const selectedSuscription: any = newSubscriptions[selectedSuscriptionIndex]
      const updatedsubscription: any = { ...selectedSuscription, workbooks: updatedWorkbooks }
      newSubscriptions.splice(selectedSuscriptionIndex, 1, updatedsubscription)
      const updatedUser = { ...user, subscriptions: newSubscriptions }
      dispatch(updateUserWorkbookFilterList(updatedUser))
      dispatch(trackEvent({ eventCategory: 'filter_action', values: `${selectedWorkbook.workbookTitle}: ${workbookTitle}`, event: `filter_delete` }))
      return ({
        isSelected,
        message: { message: `Filter ${workbookTitle} succesfully deleted.`, type: 'message' },
      })
    } catch (err) {
      dispatch(trackEvent({
        eventCategory: 'error',
        values: JSON.stringify({ err }),
        event: `deleteControlFilter_error`
      }))
      if (!(err as Record<string, string>).response) {
        throw err
      }
      return rejectWithValue(err)
    }
  }
)
let inactivityTimeout: any

const reloadWorkbook = (callback: any) => {
  clearTimeout(inactivityTimeout)
  inactivityTimeout = setTimeout(() => {
    reloadWorkbook(callback)
    callback()
  }, 1800000);
}

const getWorkBookUrlV2 = createAsyncThunk(
  "main/getWorkBookUrlV2",
  async ({ workbookGuid }: { workbookGuid: string }, { rejectWithValue, dispatch }) => {
    await apiUtils.checkToken();
    const { trackEvent } = dataTrackingActions;
    const { token } = store.getState().auth;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_V2}/v2/WorkbookEmbed/workbook/${workbookGuid}`,
        {
          headers: {
            ...apiUtils.getHeaders(),
            "Content-Type": "application/json",
            Authorization: `${token?.token_type} ${token?.access_token}`,
          },
        }
      );

      const { data } = response;

      return {
        selectedWorkbookV2: {
          workbookGuid: data.workbookGuid,
          workbookName: data.workbookName,
          workbookDisplayName: data.workbookDisplayName,
          embedUrl: data.embedUrl, 
        },
      };
    } catch (err) {
      await dispatch(
        trackEvent({
          eventCategory: "error",
          values: JSON.stringify({ err }),
          event: "getWorkBookUrlV2_error",
        })
      );
      window.location.assign(
        `${process.env.REACT_APP_LANDING_URL}/error?redirectUrl=${window.location.href}`
      );
      return rejectWithValue(err);
    }
  }
)

const getWorkBookUrl = createAsyncThunk(
  'main/getWorkBookUrl',
  async (data: any, { rejectWithValue, dispatch }) => {
    await apiUtils.checkToken()
    reloadWorkbook(() => dispatch(getWorkBookUrl({ ...data, silentReload: true })))
    const { trackEvent } = dataTrackingActions
    const { selectedSuscriptionIndex } = store.getState().main
    const { token, user } = store.getState().auth
    const payload = { ...data }
    delete payload.controlFilter
    payload.controlFilterGuid = data.controlFilter?.controlFilterGuid
    try {
      const { data: { content: selectedWorkbook } } = await axios.post(`${process.env.REACT_APP_API_URL}/v2/bitool/generateEmbedWorkbook`,
        payload,
        {
          headers: {
            ...apiUtils.getHeaders(),
            "Content-Type": "application/json-patch+json",
            Authorization: `${token?.token_type} ${token?.access_token}`
          },

        }
      );

      const { labels, defaultedTo, controlFilters, workbookElements } = user.subscriptions[selectedSuscriptionIndex].workbooks.find(({ workbookGuid }: any) => workbookGuid === selectedWorkbook?.workbookGuid)
      const controlFilter = controlFilters.find(({ controlFilterGuid }: any) => controlFilterGuid === data.controlFilter?.controlFilterGuid)
      dispatch(trackEvent({ eventCategory: 'view_workbook', values: { state: selectedWorkbook.state, categories: selectedWorkbook.categories }, event: selectedWorkbook.workbookTitle }))
      if (!data.silentReload) {
        dispatch(resetActivityTimeout())
      }
      return ({ selectedWorkbook: { ...selectedWorkbook, workbookElements, labels, defaultedTo, controlFilter } })
    } catch (err) {
      await dispatch(trackEvent({
        eventCategory: 'error',
        values: JSON.stringify({ err }),
        event: `getWorkBookUrl_error`
      }))
      await dispatch(trackEvent({ eventCategory: 'switch', values: { appSelection: 'PAM' }, event: 'switch_on_error' }))
      window.location.assign(`${process.env.REACT_APP_LANDING_URL}/error?redirectUrl=${window.location.href}`)
      if (!(err as Record<string, string>).response) {
        throw err
      }
      await dispatch(trackEvent({
        eventCategory: 'error',
        values: JSON.stringify({ err }),
        event: `getWorkBookUrl_error`
      }))
      await dispatch(trackEvent({ eventCategory: 'switch', values: JSON.stringify({ appSelection: 'PAM' }), event: 'switch_on_error' }))
      window.location.assign(`${process.env.REACT_APP_LANDING_URL}/error?redirectUrl=${window.location.href}`)
      return rejectWithValue(err)
    }
  }
)

const resetActivityTimeout = createAction('main/resetActivityTimeout', () => ({ payload: true }))

const getWorkbookElements = createAsyncThunk(
  'main/getWorkbookElements',
  async (workbookGuid2: string, { rejectWithValue, dispatch }) => {
    const { trackEvent } = dataTrackingOperations
    await apiUtils.checkToken()
    try {
      const { data } = await apiUtils.apiV2.get(`/v2/Export/workbook/${workbookGuid2}/elements`) 
      return { workbookElements: data }
    } catch (err) {
      dispatch(trackEvent({
        eventCategory: 'error',
        values: JSON.stringify({ err }),
        event: `getWorkbookElements_error`
      }))

      if (!(err as Record<string, string>).response) {
        throw err
      }
      return ({ workbookElements: [] })
    }
  }
)

const downloadWorkbook = createAsyncThunk(
  'main/exportWorkbook',
  async ({ format, workbookElementGuid, fileName }: any, { rejectWithValue, dispatch }) => {
    await apiUtils.checkToken();
    
    const { token } = store.getState().auth;
    const { appType, selectedWorkbookV2 } = store.getState().main;
    const { trackEvent } = dataTrackingOperations;

    if (!selectedWorkbookV2?.workbookGuid) {
      return rejectWithValue({ message: 'No workbook selected', type: 'error' });
    }

    try {

      const latestSigmaControls = store.getState().main.selectedWorkbookV2?.sigmaControls || [];

      const apiUrl = `${process.env.REACT_APP_API_URL_V2}/v2/Export/file/workbook/${selectedWorkbookV2.workbookGuid}`;

      const requestBody = {
        fileFormat: format,
        workbookElementGuid,
        sigmaControls: latestSigmaControls || []
      };

      const response = await axios.post(apiUrl, requestBody, {
        headers: {
          'App-Origin': appType,
          Authorization: `${token?.token_type} ${token?.access_token}`,
        },
        responseType: 'blob',
      });

      dispatch(trackEvent({
        eventCategory: eventsCategory.export_report,
        values: { ...requestBody },
        event: exportActions.download,
      }));

      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.${format}`);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);

      return { message: { message: `${fileName}.${format} downloaded`, type: 'success' } };

    } catch (err) {
      dispatch(trackEvent({
        eventCategory: 'error',
        values: JSON.stringify({ err }),
        event: `downloadWorkbook_error`,
      }));

      if (!(err as Record<string, string>).response) {
        throw err;
      }
      
      return rejectWithValue({ message: downloadErrorMessage, type: 'error', persist: true });
    }
  }
);

const sendReport = createAsyncThunk(
  'main/sendReport',
  async (params: any, { rejectWithValue, dispatch }) => {
    const { appType } = store.getState().main;
    const { trackEvent } = dataTrackingOperations;
    const { token } = store.getState().auth;
    let userReports = {};

    try {
      const url = `${process.env.REACT_APP_API_URL_V2}/v2/Export/send/workbook/${params.workbookGuid}`;

      let { workbookGuid, ...rest } = params;
      let dataSent = { ...rest };

      const { data } = await axios.post(url, dataSent, {
        headers: {
          'App-Origin': appType,
          "Content-Type": "application/json",
          Authorization: `${token?.token_type} ${token?.access_token}`
        },
      });

      dispatch(trackEvent({ eventCategory: eventsCategory.export_report, values: dataSent, event: exportActions.send_now }));

      return ({ userReports, message: { message: `Report was sent to ${params.recipients}`, type: 'message' } });

    } catch (err) {
      dispatch(trackEvent({
        eventCategory: 'error',
        values: JSON.stringify({ err }),
        event: `sendReport_error`
      }));

      if (!(err as Record<string, string>).response) {
        throw err;
      }

      return ({ userReports });
    }
  }
);

const getWorkbookFiltersLabels = createAsyncThunk(
  'main/getWorkbookFiltersLabels',
  async (workbookGuid: string, { rejectWithValue, dispatch }) => {
    await apiUtils.checkToken()
    const { trackEvent } = dataTrackingActions
    try {
      const { data } = await apiUtils.apiV2.get(`/labels/list/${workbookGuid}`)
      return { labels: data }
    } catch (err) {
      dispatch(trackEvent({
        eventCategory: 'error',
        values: JSON.stringify({ err }),
        event: `getFiltersLabels_error`
      }))
      return rejectWithValue(err)
    }
})

const updateSavedWorkbookFiltersList = createAsyncThunk('main/updateSavedWorkbookFiltersList',
  async (savedWorkbookFilters: ISavedFilter[]) => {
    return ({ savedWorkbookFilters })
})

const saveWorkbookFilters = createAsyncThunk(
    'main/saveWorkbookFilters',
    async (filterGroup: IFiltersGroup, { rejectWithValue, dispatch }) => {
      const {
        sigmaControls,
        workbookGuid,
        savedFilterGuid,
        savedFilterName,
        isDefault,
        isAutoSaving,
      } = filterGroup;
      
      await apiUtils.checkToken();
  
      if (workbookGuid) {
        await dispatch(getSavedWorkbookFilters(workbookGuid));
      }
      let updatedSavedWorkbookFilters = store.getState().main.savedWorkbookFilters;
  
      const { trackEvent } = dataTrackingActions;
  
      try {
        let existingAutoSavingFilter = updatedSavedWorkbookFilters?.find(
          (filter: any) => filter.isAutoSaving === true
        );
  
        let autoSavingFilterGuid = existingAutoSavingFilter?.savedFilterGuid || savedFilterGuid;
  
        const nameForSaving = isAutoSaving && !savedFilterName ? "" : savedFilterName;
  
        const payload = {
          isDefault: isDefault || false,
          isAutoSaving: isAutoSaving || false,
          savedFilterName: nameForSaving,
          sigmaControls: sigmaControls || store.getState().main.selectedWorkbookV2?.sigmaControls || [],
        };
  
        let content: any;
        let isNew = false;
  
        if (isAutoSaving) {
          if (autoSavingFilterGuid) {
            const { data } = await apiUtils.apiV2.put(
              `/v2/Controls/workbook/${workbookGuid}/saved/${autoSavingFilterGuid}`,
              payload
            );
            content = data;
            dispatch(
              trackEvent({
                eventCategory: eventsCategory.filter_action,
                values: { workbookGuid, savedFilterGuid: autoSavingFilterGuid, savedFilterName: nameForSaving },
                event: filterActions.filter_update,
              })
            );
          } else {
            const { data } = await apiUtils.apiV2.post(
              `/v2/Controls/workbook/${workbookGuid}`,
              payload
            );
            content = data;
            isNew = true;
            dispatch(
              trackEvent({
                eventCategory: eventsCategory.filter_action,
                values: { workbookGuid, savedFilterName: nameForSaving },
                event: filterActions.filter_save,
              })
            );

            if (workbookGuid) {
              await dispatch(getSavedWorkbookFilters(workbookGuid));
            }
          }
        } else if (savedFilterGuid) {
          const { data } = await apiUtils.apiV2.put(
            `/v2/Controls/workbook/${workbookGuid}/saved/${savedFilterGuid}`,
            payload
          );
          content = data;
          dispatch(
            trackEvent({
              eventCategory: eventsCategory.filter_action,
              values: { workbookGuid, savedFilterGuid, savedFilterName: nameForSaving },
              event: filterActions.filter_update,
            })
          );
  
          if (workbookGuid) {
            await dispatch(getSavedWorkbookFilters(workbookGuid));
          }
        } else {
          const { data } = await apiUtils.apiV2.post(
            `/v2/Controls/workbook/${workbookGuid}`,
            payload
          );
          content = data;
          isNew = true;
          dispatch(
            trackEvent({
              eventCategory: eventsCategory.filter_action,
              values: { workbookGuid, savedFilterName: nameForSaving },
              event: filterActions.filter_save,
            })
          );

          if (workbookGuid) {
            await dispatch(getSavedWorkbookFilters(workbookGuid));
          }
        }
  
        const result: ISaveWorkbookFiltersPayload = {
          filterGroup: content,
          sigmaControls: payload.sigmaControls,
          isSelected: content.isSelected,
          isDefault: content.isDefault,
          message: nameForSaving
            ? { message: `Filter ${nameForSaving} successfully ${isNew ? 'added' : 'updated'}.` }
            : undefined,
        };
  
        return result;
      } catch (err) {
        dispatch(
          trackEvent({
            eventCategory: 'error',
            values: JSON.stringify({ err }),
            event: `saveWorkbookFilters_error`,
          })
        );
        return rejectWithValue(err);
      }
    }
); 
  

const getSavedWorkbookFilters = createAsyncThunk(
  'main/getSavedWorkbookFilters',
  async (workbookGuid: string, { rejectWithValue, dispatch }) => {
    await apiUtils.checkToken();
    const { trackEvent } = dataTrackingActions;
    try {
      const { data } = await apiUtils.apiV2.get(`/v2/Controls/workbook/${workbookGuid}`);
      return { savedWorkbookFilters: data };
    } catch (err) {
      dispatch(
        trackEvent({
          eventCategory: 'error',
          values: JSON.stringify({ err }),
          event: 'getSavedWorkbookFilters_error',
        })
      );
      return rejectWithValue(err);
    }
  }
);

const getExportPremiumList = createAsyncThunk(
  'main/getExportPremiumList',
  async (_, {rejectWithValue, dispatch})=>{
    const { trackEvent } = dataTrackingOperations 
    try{
      const response = await apiUtils.apiV2.get('/exportpremium/list')
      const { data: exportPremiumList } = response
      return ({ exportPremiumList })
    }catch(err){
      dispatch(trackEvent({
        eventCategory: 'error',
        values: JSON.stringify({ err }),
        event: `getWorkbookFilters_error`
      }))

      if (!(err as Record<string, string>).response) {
        throw err
      }
      return ({ exportPremiumList: [] })
    }
  }
)

const downloadPublicFile = createAsyncThunk(
  'main/download',
  async (_, { rejectWithValue, dispatch }) => {
    const { trackEvent } = dataTrackingActions
    try{
      const response = await axios.get('/',{
        responseType: 'blob',
        baseURL:'https://file-server-5guq.onrender.com',
      })
      const { data } = response
      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Document`);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
      return ({ message: { message: `File downloaded`, type: 'success' }})
    }catch(err){
      dispatch(trackEvent({
        eventCategory: 'error',
        values: JSON.stringify({ err }),
        event: `downloadPublicFile_error`
      }))
      if (!(err as Record<string, string>).response) {
        throw err
      }
      return rejectWithValue({ message: downloadErrorMessage, type: 'error', persist: true })
    }
  }
)

const downloadPremiumExport = createAsyncThunk(
  'main/downloadPremiumExport',
  async (params: { export: IDownloadPremiumExport, fileName: string, format:string}, { dispatch, rejectWithValue })=>{
    await apiUtils.checkToken()
    const { trackEvent } = dataTrackingActions
    try{
      const response = await apiUtils.apiV2.post('/exportpremium/export', params.export,{
        headers: {
          'App-Origin': store.getState().main.appType,
        },
        responseType: 'blob'
      })
      const { format, fileName } = params
      dispatch(trackEvent({ eventCategory: eventsCategory.export_report, values: { ...params }, event: exportActions.premium_download }))
      const { data } = response
      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.${format}`);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
      return ({ message: { message: `${fileName}.${format} downloaded`, type: 'success' } })
    }catch(err){
      dispatch(trackEvent({
        eventCategory: 'error',
        values: JSON.stringify({ err }),
        event: `downloadPremiumExport_error`
      }))
      if (!(err as Record<string, string>).response) {
        throw err
      }
      return rejectWithValue({ message: downloadErrorMessage, type: 'error', persist: true })
    }
  }
)

const getWorkBookPremiumFilters = createAsyncThunk(
  'main/getWorkbookPremiumFilters', 
  async (params: {workbookGuid: string}, { dispatch })=>{
    await apiUtils.checkToken()
    const { trackEvent } = dataTrackingOperations
    try {
      const response = await apiUtils.apiV2.post('/filters/getFilters',
        params
      );
      const { data: { filters } } = response;
      return ({ exportPremiumFilters: filters });
    } catch (err) {
      dispatch(trackEvent({
        eventCategory: 'error',
        values: JSON.stringify({ err }),
        event: `getWorkbookFilters_error`
      }))

      if (!(err as Record<string, string>).response) {
        throw err
      }
      return ({ exportPremiumFilters: [] })
    }
  }
)

const getWorkbookFilters = createAsyncThunk(
  'main/getWorkbookFilters',
  async (params: any, { rejectWithValue, dispatch }) => {
    await apiUtils.checkToken()
    const { trackEvent } = dataTrackingOperations
    try {
      const response = await apiUtils.apiV2.post('/filters/getFilters',
        params
      );
      const { data: { filters } } = response;
      return ({ filters });
    } catch (err) {
      dispatch(trackEvent({
        eventCategory: 'error',
        values: JSON.stringify({ err }),
        event: `getWorkbookFilters_error`
      }))

      if (!(err as Record<string, string>).response) {
        throw err
      }
      return ({ filters: [] })
    }
  }
)

const getUserReports = createAsyncThunk(
  'main/getUserReports',
  async (workbookGuid: any, { rejectWithValue, dispatch }) => {
    await apiUtils.checkToken()
    const { trackEvent } = dataTrackingOperations
    try {

      const { data } = await apiUtils.apiV2.get(`/v2/Export/scheduled/workbook/${workbookGuid}`)
      return ({ userReports: data })
    } catch (err) {
      dispatch(trackEvent({
        eventCategory: 'error',
        values: JSON.stringify({ err }),
        event: `getUserReports_error`
      }))

      if (!(err as Record<string, string>).response) {
        throw err
      }
      return ({ userReports: [] })
    }
  }
)

const scheduleSendReportV2 = createAsyncThunk('main/sendReportv2',
  async (params: IScheduledReportParams, { dispatch, rejectWithValue }) => {
    const { savedWorkbookFilters, selectedWorkbookV2 } = store.getState().main;
    const { trackEvent } = dataTrackingOperations;
    const payload = structuredClone(params);
    const { filtersGroup } = payload;

    await apiUtils.checkToken();

    try {

      if (!params.filtersGroup.savedFilterGuid) {
        const filterPayload = {
          isDefault: filtersGroup.isDefault || false,
          isAutoSaving: filtersGroup.isAutoSaving || false,
          savedFilterName: filtersGroup.savedFilterName || "",
          sigmaControls: filtersGroup.sigmaControls || selectedWorkbookV2?.sigmaControls || []
        };

        const { data } = await apiUtils.apiV2.post(`/v2/Controls/workbook/${filtersGroup.workbookGuid}`, filterPayload);
        payload.filtersGroup.savedFilterGuid = data?.savedFilterGuid;

        const newSavedWorkbookFilters = [data, ...savedWorkbookFilters!];
        dispatch(updateSavedWorkbookFiltersList(newSavedWorkbookFilters!));

        dispatch(trackEvent({
          eventCategory: eventsCategory.filter_action,
          values: { workbookGuid: filtersGroup.workbookGuid, filterData: filterPayload },
          event: filterActions.filter_save
        }));
      }

      const { workbookGuid, savedFilterGuid, sigmaControls } = payload.filtersGroup;
      const { workbookElementGuidList, scheduledReport } = payload;

      if (!workbookGuid) {
        return rejectWithValue({ message: 'No workbook selected', type: 'error' });
      }

      const apiUrl = `${process.env.REACT_APP_API_URL_V2}/v2/Export/scheduled/workbook/${workbookGuid}`;

      const requestBody = {
        fileFormat: scheduledReport.fileFormat,
        workbookElementGuidList,
        savedFilterGuid,
        emailSubject: scheduledReport.emailSubject,
        emailBody: scheduledReport.emailBody,
        recipients: scheduledReport.recipients,
        cronExpression: scheduledReport.cronExpression,
        timeZone: scheduledReport.timeZone,
        sigmaControls: sigmaControls || selectedWorkbookV2?.sigmaControls || []
      };

      await apiUtils.apiV2.post(apiUrl, requestBody);

      dispatch(trackEvent({
        eventCategory: eventsCategory.export_scheduled_report,
        values: { workbookGuid, ...requestBody },
        event: scheduledExportActions.created
      }));

    } catch (err) {
      dispatch(trackEvent({
        eventCategory: 'error',
        values: JSON.stringify({ err }),
        event: `scheduleSendReportV2_error`
      }));

      if (!(err as Record<string, string>).response) {
        throw err;
      }
      return rejectWithValue({ message: err.response.data[0], type: 'error', persist: false });
    }
  }
)

const scheduleSendReport = createAsyncThunk(
  'main/sendReport',
  async (params: any, { rejectWithValue, dispatch }) => {
    await apiUtils.checkToken()
    const { updateUserWorkbookFilterList } = authOperations
    const { selectedWorkbook, selectedSuscriptionIndex } = store.getState().main
    const { token, user } = store.getState().auth
    const payload = { ...params }
    delete payload.controlFilterParams
    const values = {
      ...params.controlFilterParams,
    }
    delete values.silentUpdate
    delete values.presetName
    delete values.workbookGuid
    delete values.categories
    delete values.state
    const controlValues = Object.keys(values).map((k: any) => ({ controlId: k, values: params.controlFilterParams[k] }))
    const controlFilterParams = {
      categories: params.controlFilterParams.categories,
      state: params.controlFilterParams.state,
      workbookGuid: params.workbookGuid,
      name: params.controlFilterParams.presetName,
      controlValues
    }
    const { trackEvent } = dataTrackingOperations
    let userReports = {}
    try {
      if (!params.controlFilterGuid) {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/v2/bitool/controlFilters`,
          controlFilterParams,
          {
            headers: {
              ...apiUtils.getHeaders(),
              "Content-Type": "application/json-patch+json",
              Authorization: `${token?.token_type} ${token?.access_token}`
            },
          }
        );

        payload.controlFilterGuid = data.content.controlFilterGuid
        const updatedWorkbook: any = user.subscriptions[selectedSuscriptionIndex].workbooks.map((wb: any) => {
          const controlFilters = [
            ...wb.controlFilters.filter(({ controlFilterGuid }: any) => controlFilterGuid !== data.content.controlFilterGuid),
            { ...data.content }
          ]
          if (wb.workbookGuid === selectedWorkbook?.workbookGuid) {
            return ({
              ...wb,
              controlFilters
            })
          }
          return (wb)
        })
        const updatedsubscription = { ...user.subscriptions[selectedSuscriptionIndex], workbooks: updatedWorkbook }
        const updatedsubscriptions = [...user.subscriptions]
        updatedsubscriptions.splice(selectedSuscriptionIndex, 1, updatedsubscription)
        const updatedUser = { ...user, subscriptions: updatedsubscriptions }


        dispatch(updateUserWorkbookFilterList(updatedUser))
      }
      await axios.post(`${process.env.REACT_APP_API_URL}/v2/bitool/scheduleSendReport`,
        payload,
        {
          headers: {
            ...apiUtils.getHeaders(),
            "Content-Type": "application/json-patch+json",
            Authorization: `${token?.token_type} ${token?.access_token}`
          },
        }
      );

      return ({ userReports })
    } catch (err) {
      dispatch(trackEvent({
        eventCategory: 'error',
        values: JSON.stringify({ err }),
        event: `scheduleSendReport_error`
      }))

      if (!(err as Record<string, string>).response) {
        throw err
      }
      return ({ userReports })
    }
  }
)

const updateScheduleSendReportV2 = createAsyncThunk(
  'main/updateScheduleSendReportV2',
  async ({ scheduledReport, filtersGroup, workbookElementGuidList }: IScheduledReportParams, { rejectWithValue, dispatch }) => {
    await apiUtils.checkToken();
    const { savedWorkbookFilters, selectedWorkbookV2 } = store.getState().main;
    const { trackEvent } = dataTrackingActions;
    let userReports = {};
    
    try {
      if (!filtersGroup.savedFilterGuid) {
        const filterPayload = {
          isDefault: filtersGroup.isDefault || false,
          isAutoSaving: filtersGroup.isAutoSaving || false,
          savedFilterName: filtersGroup.savedFilterName || "",
          sigmaControls: filtersGroup.sigmaControls || selectedWorkbookV2?.sigmaControls || []
        };

        const { data } = await apiUtils.apiV2.post(`/v2/Controls/workbook/${filtersGroup.workbookGuid}`, filterPayload);
        filtersGroup.savedFilterGuid = data?.savedFilterGuid;

        const newSavedWorkbookFilters = [data, ...savedWorkbookFilters!];
        dispatch(updateSavedWorkbookFiltersList(newSavedWorkbookFilters!));

        dispatch(trackEvent({
          eventCategory: filterActions.filter_save,
          values: { workbookGuid: filtersGroup.workbookGuid, filterData: filterPayload },
          event: filterActions.filter_save
        }));
      }

      const { workbookGuid, savedFilterGuid } = filtersGroup;

      if (!workbookGuid) {
        return rejectWithValue({ message: 'No workbook selected', type: 'error' });
      }

      const apiUrl = `${process.env.REACT_APP_API_URL_V2}/v2/Export/scheduled/workbook/${workbookGuid}/report/${scheduledReport.reportId}`;

      const requestBody = {
        fileFormat: scheduledReport.fileFormat,
        workbookElementGuidList,
        savedFilterGuid,
        emailSubject: scheduledReport.emailSubject,
        emailBody: scheduledReport.emailBody,
        recipients: scheduledReport.recipients,
        cronExpression: scheduledReport.cronExpression,
        timeZone: scheduledReport.timeZone
      };

      await apiUtils.apiV2.put(apiUrl, requestBody);

      dispatch(trackEvent({
        eventCategory: eventsCategory.export_scheduled_report,
        values: { workbookGuid, ...requestBody },
        event: scheduledExportActions.edited
      }));

    } catch (err) {
      dispatch(trackEvent({
        eventCategory: 'error',
        values: JSON.stringify({ err }),
        event: `updateScheduleSendReportV2_error`
      }));

      if (!(err as Record<string, string>).response) {
        throw err;
      }
      return ({ userReports });
    }
  }
)

const updateScheduleSendReport = createAsyncThunk(
  'main/updateScheduleSendReport',
  async (params: any, { rejectWithValue, dispatch }) => {
    await apiUtils.checkToken()
    const { updateUserWorkbookFilterList } = authOperations
    const { token, user } = store.getState().auth
    const { selectedSuscriptionIndex, selectedWorkbook } = store.getState().main

    const { trackEvent } = dataTrackingOperations
    const payload = { ...params }
    delete payload.controlFilterParams
    const values = {
      ...params.controlFilterParams,
    }
    delete values.silentUpdate
    delete values.presetName
    delete values.workbookGuid
    delete values.categories
    delete values.state

    const controlValues = Object.keys(values).map((k: any) => ({ controlId: k, values: params.controlFilterParams[k] }))
    const controlFilterParams = {
      state: params.controlFilterParams.state,
      workbookGuid: params.workbookGuid,
      name: params.controlFilterParams.presetName,
      controlValues
    }
    let userReports = {}
    try {
      if (!params.controlFilterGuid) {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/v2/bitool/controlFilters`,
          controlFilterParams,
          {
            headers: {
              ...apiUtils.getHeaders(),
              "Content-Type": "application/json-patch+json",
              Authorization: `${token?.token_type} ${token?.access_token}`
            },
          }
        );

        payload.controlFilterGuid = data.content.controlFilterGuid
        const updatedWorkbook: any = user.subscriptions[selectedSuscriptionIndex].workbooks.map((wb: any) => {
          if (wb.workbookGuid === selectedWorkbook?.workbookGuid) {
            return ({
              ...wb,
              controlFilters: [...wb.controlFilters.filter(({ controlFilterGuid }: any) => controlFilterGuid !== data.content.controlFilterGuid), { ...data.content }]
            })
          }
          return (wb)
        })
        const updatedsubscription = { ...user.subscriptions[selectedSuscriptionIndex], workbooks: updatedWorkbook }
        const updatedsubscriptions = [...user.subscriptions]
        updatedsubscriptions.splice(selectedSuscriptionIndex, 1, updatedsubscription)
        const updatedUser = { ...user, subscriptions: updatedsubscriptions }
        dispatch(updateUserWorkbookFilterList(updatedUser))
      }
      await axios.put(`${process.env.REACT_APP_API_URL}/v2/bitool/updateScheduledReport/${params.reportId}`,
        payload,
        {
          headers: {
            ...apiUtils.getHeaders(),
            "Content-Type": "application/json-patch+json",
            Authorization: `${token?.token_type} ${token?.access_token}`
          },
        }
      );
      dispatch(updateUserWorkbookFilterList(user))
      return ({ userReports })
    } catch (err) {
      dispatch(trackEvent({
        eventCategory: 'error',
        values: JSON.stringify({ err }),
        event: `updateScheduledSendReport_error`
      }))

      if (!(err as Record<string, string>).response) {
        throw err
      }
      return ({ userReports })
    }
  }
)

const deleteReport = createAsyncThunk(
  'main/deleteReport',
  async ({ reportId, workbookGuid }: { reportId: string; workbookGuid: string }, { rejectWithValue, dispatch }) => {
    await apiUtils.checkToken();
    const { trackEvent } = dataTrackingOperations;
    const { token } = store.getState().auth;

    try {
      if (!workbookGuid || !reportId) {
        return rejectWithValue({ message: 'Missing workbookGuid or reportId', type: 'error' });
      }

      // ✅ Construcción de la URL con `workbookGuid` y `reportId`
      const apiUrl = `${process.env.REACT_APP_API_URL_V2}/v2/Export/scheduled/workbook/${workbookGuid}/report/${reportId}`;

      // ✅ Llamada al API para eliminar el reporte
      await axios.delete(apiUrl, {
        headers: {
          ...apiUtils.getHeaders(),
          "Content-Type": "application/json",
          Authorization: `${token?.token_type} ${token?.access_token}`
        },
      });

      // ✅ Registrar evento de eliminación
      dispatch(trackEvent({
        eventCategory: eventsCategory.export_scheduled_report,
        values: { workbookGuid, reportId },
        event: scheduledExportActions.deleted
      }));

    } catch (err) {
      // 🔥 Registrar error
      dispatch(trackEvent({
        eventCategory: 'error',
        values: JSON.stringify({ err }),
        event: `deleteReport_error`
      }));

      if (!(err as Record<string, string>).response) {
        throw err;
      }
    }
  }
)

const updateScheduledReportStatus = createAsyncThunk(
  'main/updateScheduledReportStatus',
  async ({ reportId, status, workbookGuid }: { reportId: string; status: string; workbookGuid: string }, { rejectWithValue, dispatch }) => {
    await apiUtils.checkToken();
    const { trackEvent } = dataTrackingOperations;
    const { token } = store.getState().auth;

    try {
      if (!workbookGuid || !reportId) {
        return rejectWithValue({ message: 'Missing workbookGuid or reportId', type: 'error' });
      }

      const isActive = status === 'active'; 
      const requestBody = { isActive };
      const apiUrl = `${process.env.REACT_APP_API_URL_V2}/v2/Export/scheduled/workbook/${workbookGuid}/report/${reportId}/status`;

      const response = await axios.put(apiUrl, requestBody, {
        headers: {
          ...apiUtils.getHeaders(),
          "Content-Type": "application/json",
          Authorization: `${token?.token_type} ${token?.access_token}`
        },
      });

      const actionType = isActive ? scheduledExportActions.started : scheduledExportActions.paused;

      dispatch(trackEvent({
        eventCategory: eventsCategory.export_scheduled_report,
        values: { workbookGuid, reportId, isActive },
        event: actionType
      }));

      await dispatch(getUserReports(workbookGuid));

    } catch (err) {
      dispatch(trackEvent({
        eventCategory: 'error',
        values: JSON.stringify({ err }),
        event: `updateScheduledReportStatus_error`
      }));

      if (!(err as Record<string, string>).response) {
        throw err;
      }
    }
  }
);

const updateSelectedFilterSaved = createAsyncThunk(
  'main/updateSelectedFilterSaved',
  (selectedSavedFilter: any) => ({ selectedSavedFilter })
)

const updateDefaultFilterSaved = createAsyncThunk(
  'main/updateDefaultFilterSaved',
  (defaultFilterSaved: any) => ({ defaultFilterSaved })
)

const getHealthStatus = createAsyncThunk(
  'main/getHealthStatus',
  async (token : any, { rejectWithValue, dispatch }) =>{
    await apiUtils.checkToken()
    const { trackEvent } = dataTrackingOperations
    const url = `${process.env.REACT_APP_API_URL_V2}/UserSettings/health`
    try{
      const { data } = await axios.get(url,{
        headers:{

            ...apiUtils.getHeaders(),
            Authorization: `${token?.token_type} ${token?.access_token}`
        }
      })
      return({ data })
    }catch(err){
      dispatch(trackEvent({
        eventCategory: 'error',
        values: JSON.stringify({ err }),
        event: `getHealthStatus_error`
      }))

      if (!(err as Record<string, string>).response) {
        throw err
      }
      return rejectWithValue(err)
    }
  }
)

const getSigmaFilters = createAsyncThunk(
  "filters/getSigmaFilters",
  async (
    { workbookGuid, savedFilterGuid }: { workbookGuid: string; savedFilterGuid?: string },
    { rejectWithValue, dispatch }
  ) => {
    await apiUtils.checkToken();
    const { trackEvent } = dataTrackingOperations;
    const { token } = store.getState().auth;

    if (!savedFilterGuid) {
      console.warn("getSigmaFilters: savedFilterGuid es undefined o vacío. No se hará la solicitud.");
      return rejectWithValue({ sigmaControls: [] });
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_V2}/v2/Controls/workbook/${workbookGuid}/saved/${savedFilterGuid}`,
        {
          headers: {
            ...apiUtils.getHeaders(),
            "Content-Type": "application/json",
            Authorization: `${token?.token_type} ${token?.access_token}`,
          },
        }
      );

      const formattedFilters = response.data.sigmaControls.map((filter: any) => ({
        sigmaControlId: filter.sigmaControlId,
        values: decodeURIComponent(filter.values || ""),
      }));

      return {
        sigmaControls: formattedFilters,
        savedFilterGuid: response.data.savedFilterGuid,
        savedFilterName: response.data.savedFilterName,
        isDefault: response.data.isDefault,
      };
    } catch (err) {
      dispatch(
        trackEvent({
          eventCategory: "error",
          values: JSON.stringify({ err }),
          event: "getSigmaFilters_error",
        })
      );

      if (!(err as Record<string, string>).response) {
        throw err;
      }
      return rejectWithValue({ sigmaControls: [] });
    }
  }
);

const mainActions = {
  sendReport,
  setMenuOpen,
  setBottomTabNavigation,
  setAppType,
  selectState,
  deleteReport,
  getWorkbookFiltersLabels,
  getSavedWorkbookFilters,
  updateSavedWorkbookFiltersList,
  saveWorkbookFilters,
  getWorkBookUrl,
  getWorkBookUrlV2,
  getWorkbookElements,
  getUserReports,
  getWorkbookFilters,
  selectWorkbook,
  selectCategories,
  downloadWorkbook,
  scheduleSendReportV2,
  scheduleSendReport,
  deleteControlFilterV2,
  deleteControlFilter,
  selectWorkbookGroup,
  updateControlFilters,
  resetActivityTimeout,
  updateScheduleSendReportV2,
  updateScheduleSendReport,
  setSelectedSuscriptionIndex,
  updateScheduledReportStatus,
  setSidebarOpen,
  setMainContent,
  setModalOpen,
  updateSelectedFilterSaved,
  updateDefaultFilterSaved,
  getWorkbookPreview,
  setSelectedWorkbookGuid,
  setSelectedModalContent,
  setIsModalOpen,
  getExportPremiumList,
  getWorkBookPremiumFilters,
  downloadPremiumExport,
  downloadPublicFile,
  getHealthStatus,
  showWorkbookActions,
  getSigmaFilters
}

export default mainActions