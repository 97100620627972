import actions from './actions'

const mainOperations = {
  setBottonTabNavigation: actions.setBottomTabNavigation,
  setMenuOpen: actions.setMenuOpen,
  setAppType: actions.setAppType,
  getWorkbookFiltersLabels: actions.getWorkbookFiltersLabels,
  setSelectedSuscriptionIndex: actions.setSelectedSuscriptionIndex,
  getSavedWorkbookFilters: actions.getSavedWorkbookFilters,
  updateSavedWorkbookFiltersList: actions.updateSavedWorkbookFiltersList,
  saveWorkbookFilters: actions.saveWorkbookFilters,
  getWorkBookUrl: actions.getWorkBookUrl,
  getWorkBookUrlV2: actions.getWorkBookUrlV2,
  downloadWorkbook: actions.downloadWorkbook,
  updateControlFilters: actions.updateControlFilters,
  deleteControlFilterV2:actions.deleteControlFilterV2,
  deleteControlFilter: actions.deleteControlFilter,
  selectWorkbookGroup: actions.selectWorkbookGroup,
  getUserReports: actions.getUserReports,
  getWorkbookFilters: actions.getWorkbookFilters,
  selectWorkbook: actions.selectWorkbook,
  selectState: actions.selectState,
  sendReport: actions.sendReport,
  deleteReport: actions.deleteReport,
  updateScheduledReportStatus: actions.updateScheduledReportStatus,
  updateScheduleSendReportV2:actions.updateScheduleSendReportV2,
  updateScheduleSendReport: actions.updateScheduleSendReport,
  scheduleSendReportV2:actions.scheduleSendReportV2,
  scheduleSendReport: actions.scheduleSendReport,
  setSidebarOpen: actions.setSidebarOpen,
  setSelectedModalContent: actions.setSelectedModalContent,
  setMainContent: actions.setMainContent,
  setModalOpen: actions.setModalOpen,
  getWorkbookElements: actions.getWorkbookElements,
  updateSelectedFilterSaved: actions.updateSelectedFilterSaved,
  updateDefaultFilterSaved: actions.updateDefaultFilterSaved,
  getWorkbookPreview: actions.getWorkbookPreview,
  setSelectedWorkbookGuid: actions.setSelectedWorkbookGuid,
  getExportPremiumList: actions.getExportPremiumList,
  getWorkBookPremiumFilters: actions.getWorkBookPremiumFilters,
  downloadPremiumExport: actions.downloadPremiumExport,
  downloadPublicFile: actions.downloadPublicFile,
  getHealthStatus: actions.getHealthStatus,
  showWorkbookActions: actions.showWorkbookActions,
  getSigmaFilters: actions.getSigmaFilters,
}


export default mainOperations
